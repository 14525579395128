import jquery from "jquery";

export default (window.$ = window.jQuery = jquery);

$(".share-popup").click(function(){
    var window_size = "width=585,height=511";
    var url = this.href;
    var domain = url.split("/")[2];
    switch(domain) {
        case "www.facebook.com":
            window_size = "width=650,height=650";
            break;
        case "www.twitter.com":
            window_size = "width=650,height=650";
            break;
        case "plus.google.com":
            window_size = "width=650,height=650";
            break;
    }
    window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
    return false;
});

$(document).ready(function() {

    $('.collapse').on('shown.bs.collapse', function () {
        $(this).parent().addClass('active');
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).parent().removeClass('active');
    });

});