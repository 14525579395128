const megaMenuSelectors = '.nav-item.dropdown.megamenu';

$(megaMenuSelectors).on('show.bs.dropdown', function (e) {
  // document.querySelector(megaMenuSelectors).classList.replace('closed-megamenu','opening-megamenu')
  e.currentTarget.classList.replace('closed-megamenu','opening-megamenu')
})
$(megaMenuSelectors).on('shown.bs.dropdown', function (e) {
  // document.querySelector(megaMenuSelectors).classList.replace('opening-megamenu','open-megamenu')
  e.currentTarget.classList.replace('opening-megamenu','open-megamenu')
  document.querySelector('main').classList.add('sidebar-active')
  document.querySelector('#body-content').classList.add('body-fixed')
})
$(megaMenuSelectors).on('hide.bs.dropdown', function (e) {
  // document.querySelector(megaMenuSelectors).classList.replace('open-megamenu','closing-megamenu')
  e.currentTarget.classList.replace('open-megamenu','closing-megamenu')
})
$(megaMenuSelectors).on('hidden.bs.dropdown', function (e) {
  const toggleCondition = document.querySelector('#navbarContent').classList.contains('open-sidebar')
  document.querySelector('main').classList.toggle('sidebar-active', toggleCondition)
  document.querySelector('#body-content').classList.toggle('body-fixed', toggleCondition)
  // document.querySelector(megaMenuSelectors).classList.replace('closing-megamenu','closed-megamenu')
  e.currentTarget.classList.replace('closing-megamenu','closed-megamenu')
})