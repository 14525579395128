import './jquery'
import 'bootstrap'
import './components/menu-icon.js'
import './components/search-works-slider.js'
import './components/home-slider.js'
import './components/solutions-bar.js'
import './components/megamenu-events.js'
import './components/sticky-element.js'
import './components/bootstrap-select.js'
import './components/enquiry-modal.js'
import './components/feedback-modal.js'
import './components/rating-input.js'
import './components/rating-input-enquiry.js'
import './components/search-live.js'
import './components/viewport.js'
import './components/print.js'