// import $ from 'jquery'
// import 'bootstrap'

let clickBuffered = true
const clickBufferTime = 750
$('#navbarContent').collapse({
	toggle: false
})

$('#navbarContent').on('show.bs.collapse', function () {
	document.querySelector('#navbarContent').classList.replace('closed-sidebar', 'opening-sidebar')
})
$('#navbarContent').on('shown.bs.collapse', function () {
	document.querySelector('#navbarContent').classList.replace('opening-sidebar', 'open-sidebar')
})
$('#navbarContent').on('hide.bs.collapse', function () {
	document.querySelector('#navbarContent').classList.replace('open-sidebar', 'closing-sidebar')
})
$('#navbarContent').on('hidden.bs.collapse', function () {
	document.querySelector('#navbarContent').classList.replace('closing-sidebar', 'closed-sidebar')
})

$('#navbarContent').on('shown.bs.collapse', function () {
	document.querySelector('#body-content').classList.add('body-fixed')
})
$('#navbarContent').on('hide.bs.collapse', function () {
	document.querySelector('#body-content').classList.remove('body-fixed')
})

$('#menuIcon').click(function () {
	if (clickBuffered) {
		clickBuffered = false

		$(this).toggleClass('open')

		const toggleConditionOne = document.querySelector('.nav-item.dropdown.megamenu').classList.contains('open-megamenu');
		const toggleConditionTwo = document.querySelector('#navbarContent').classList.contains('closed-sidebar')
		const toggleCondition = toggleConditionOne || toggleConditionTwo;
		document.querySelector('main').classList.toggle('sidebar-active', toggleCondition)

		$('#navbarContent').collapse('toggle')

		setTimeout(() => {
			clickBuffered = true
		}, clickBufferTime)

	}
})

$(window).on('resize', function () {
	if ($(window).width() > 992 && document.querySelector('#navbarContent').classList.contains('open-sidebar')) {
		const toggleCondition = document.querySelector('.nav-item.dropdown.megamenu').classList.contains('open-megamenu');
		document.querySelector('main').classList.toggle('sidebar-active', toggleCondition)
		$('#menuIcon').toggleClass('open')
		$('#navbarContent').collapse('toggle')
	} else if ($(window).width() < 992 && document.querySelector('.nav-item.dropdown.megamenu.open-megamenu')) {
		const toggleCondition = document.querySelector('#navbarContent').classList.contains('open-sidebar');
		document.querySelector('main').classList.toggle('sidebar-active', toggleCondition)
		$('.nav-item.dropdown.megamenu.open-megamenu').dropdown('toggle')
	}
});