// import $ from 'jquery';
import 'slick-carousel';

const $slick_slider = $(".home-slider")
const settings = {

  // normal options...
  cssEase: 'linear',
  infinite: false,
  slidesToShow: 1.1,
  slidesToScroll: 1,
  dots: false,
  mobileFirst:true,
  speed: 800,
  centerMode: false,
  variableWidth: false,
  autoplay: false,
  autoplaySpeed: 2000,
  touchThreshold: 500,
  touchMove: true,

  // the magic
  responsive: [
    {
      breakpoint: 2800,
      settings: {
        slidesToShow: 1.1,
      }

    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1.11,
      }

    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.11,
      }
    },
    {

      breakpoint: 768,
      settings: {
        slidesToShow: 1.11,
      }
    },
    {

      breakpoint: 576,
      settings: {
        slidesToShow: 1.11,
      }
    }]
}
$slick_slider.slick(settings);