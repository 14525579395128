// import $ from 'jquery';
import 'slick-carousel';

const $slick_slider = $(".search-works")
const settings = {

  // normal options...
  infinite: false,
  slidesToShow: 1,
  dots: true,
  mobileFirst:true,

  // the magic
  responsive: [
    {
      breakpoint: 2800,
      settings: "unslick"

    },
    {
      breakpoint: 1200,
      settings: "unslick"

    },
    {
      breakpoint: 991,
      settings: "unslick"
    },
    {

      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    },
    {

      breakpoint: 575,
      settings: {
        slidesToShow: 1
      }
    }]
}
$slick_slider.slick(settings);

// reslick only if it's not slick()
$(window).on('resize', function() {
  if ($(window).width() < 768 && $slick_slider.hasClass('slick-initialized') ) {
    // $slick_slider.slick('unslick');
  } else if (!$slick_slider.hasClass('slick-initialized')) {
    $slick_slider.slick(settings);
  }
});
