// import $ from 'jquery';
import 'slick-carousel';

const $slick_slider = $(".solutions-bar")
const settings = {

  // normal options...
  cssEase: 'linear',
  infinite: false,
  draggable:true,
  slidesToShow: 1.25,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  mobileFirst: true,
  speed: 500,
  centerMode: false,
  variableWidth: false,
  autoplay: false,
  autoplaySpeed: 2000,
  touchThreshold: 500,
  touchMove: true,

  // the magic
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
      }

    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
      }

    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3.25,
      }
    },
    {

      breakpoint: 768,
      settings: {
        slidesToShow: 2.25,
      }
    },
    {

      breakpoint: 576,
      settings: {
        slidesToShow: 2.25,
      }
    }]
}
$slick_slider.slick(settings);